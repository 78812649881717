import { AosObservable } from "@/models";
import i18n from "@/setup/i18n";
import { utils as aosUtils } from "@socotec.io/socio-aos-component";
import { computed } from "vue";
export const aosStructures = computed(() =>
  aosUtils.aosConst.AOS_ITEMS_MODULE_NAMES.map((m) => ({
    value: m,
    text: i18n.tc(`aos.structureName.${m}`),
  }))
);

export class PerimeterFactory {
  observableIds = [];
  observables = [];
  perimeter = {};

  constructor(perimeter, observableIds) {
    this.perimeter = perimeter;
    this.observableIds = observableIds;
  }

  toAmosMulti() {
    const lowestStructureType = Object.keys(this.perimeter).pop();
    const aosItems = this.perimeter[lowestStructureType];
    this.observables = AosObservable.query()
      .where((obs) => aosItems.includes(obs.aosItem))
      .get();

    return this;
  }

  toAmosSingle() {
    const lowestStructureType = Object.keys(this.perimeter).pop();
    const [aosItem] = this.perimeter[lowestStructureType];
    this.observables = [
      AosObservable.query()
        .where((obs) => aosItem === obs.aosItem)
        .first(),
    ];

    return this;
  }

  getUuids() {
    return this.observables.map((pObs) => pObs.uuid);
  }

  getAosItems() {
    return this.observables.map((pObs) => pObs.aosItem);
  }

  get() {
    return this.observables;
  }

  toAosRepresentation() {
    return this.observableIds.reduce((acc, obsId) => {
      const observable = AosObservable.find(obsId);
      if (!acc[observable.assetType]) {
        acc[observable.assetType] = [];
      }
      acc[observable.assetType].push(observable.aosItem);
      return acc;
    }, {});
  }
}
