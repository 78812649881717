import { socioGrpcClient } from "@/setup/socioGrpcClient";
import google_protobuf_struct_pb from "google-protobuf/google/protobuf/struct_pb";
import { camelToSnakeCase } from "./helpers";

export const setNestedRequest = (
  data,
  key,
  Request,
  otherExcludedFields = []
) => {
  let allExcludedFields = ["createdAt", "updatedAt"];
  if (otherExcludedFields.length) {
    allExcludedFields.push(...otherExcludedFields);
  }
  data[key] = data[key].map((elem) =>
    socioGrpcClient.javascriptToRequest(Request, elem, allExcludedFields)
  );
  return data;
};

/*
 ** Replace all `undefined` object values by `null`
 ** gRPC's functions for transforming objects into protobuf struct are crap
 ** undefined values are not handled properly... that's why this function exists
 */
export const replaceUndefinedWithNull = (obj) => {
  for (let key in obj) {
    if (Object.hasOwn(obj, key)) {
      if (obj[key] === undefined) {
        obj[key] = null;
      } else if (typeof obj[key] === "object") {
        obj[key] = replaceUndefinedWithNull(obj[key]);
      }
    }
  }
  return obj;
};

/*
 ** Set filters in protobuf message
 ** This makes it possible to pass large amounts of data through filters
 ** without being restricted by the maximum size of headers imposed by most web browsers
 ** Warning: Not supported by all gRPC methods (02/2023)
 */
export const setRequestFilters = ({ request, filters }) => {
  let requestFilters;

  requestFilters = Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [
      camelToSnakeCase(key),
      value,
    ])
  );
  requestFilters = replaceUndefinedWithNull(requestFilters);
  const structFilters = new google_protobuf_struct_pb.Struct.fromJavaScript(
    requestFilters
  );
  request.setFilters(structFilters);
  return request;
};
