import i18n from "@/setup/i18n";
import store from "@/store";

export const downloadDocument = async (docUuid) => {
  const data = await store.dispatch(
    "document/retrieveDocumentCustomUrl",
    docUuid
  );

  if (!data?.documentUrl) {
    store.dispatch(
      "notifications/showErrorNotification",
      i18n.t("documentFormModal.retrieveError")
    );
    return;
  }
  let downloadLink = document.createElement("a");
  downloadLink.href = data.documentUrl;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadBase64Document = (base64, filename, content_type) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = `data:${content_type};base64,${base64}`;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const PHOTO_PAGE_LENGTH = 20;

export const THUMBNAIL_SIZES = ["240x240", "512x512"];

export const PHOTO_DOC_TYPE = ["PHOTO"];

export const PICTURE_FILE_TYPES = ["jpg", "jpeg", "png"];

export const CNIL_LINK =
  "https://www.cnil.fr/fr/cnil-direct/question/une-donnee-caractere-personnel-cest-quoi";

export const convertFileSizeToMo = (size) => {
  if (typeof size !== "number" || size <= 0) return 0;

  const sizeInMo = size / (1024 * 1024);
  return parseFloat(sizeInMo.toFixed(0));
};

export const MAX_DOCUMENT_SIZE_MB = 50;
export const MAX_ARCHIVE_SIZE_MB = 60;
