import { v4 as uuidv4 } from "uuid";
import { Action, Periodicity } from "@/models";
import { baseExcludedFields } from "./const";
import { differenceBy } from "lodash";
import { useRxdbCollection } from "@/composables/useRxdbCollection";
import RxDB, { makeSchemaCompliantKeepingKeys } from "@/rxdb/utils";

export const getPriorityConfig = (action, actionConfigTable, type) => {
  if (!action?.priorityData) {
    return "";
  }
  const priorityValue = actionConfigTable?.priority?.storedData?.find(
    (priority) => priority.value === action.priorityData?.value
  );

  return priorityValue ? priorityValue[type] : "";
};

export const buildActionRequestForConnect = (payload) => {
  const { periodicity = null, contributors = [], ...action } = payload;
  const request = action;

  if (periodicity) {
    const periodicityUuid = periodicity?.uuid ?? periodicity;
    const periodicityData = Periodicity.find(periodicityUuid);
    if (periodicityUuid.startsWith("$")) {
      periodicityData.uuid = null;
    }
    for (const key of baseExcludedFields) {
      delete periodicityData[key];
    }
    request.periodicity = periodicityData;
  }
  if (contributors?.length) {
    request.contributors = contributors.map((uuid) => {
      return { contributorUuid: uuid };
    });
  } else {
    request.contributors = [];
  }
  request.observations = action.observationsList;

  if (!action.totalAmount) {
    delete request.totalAmount;
  }

  return request;
};

export const handleSuggestedActionsIfApplicable = ({
  incommingActionTypes,
  currentActions,
  analyticalAxesList,
  observationsList,
}) => {
  const existingActionTypes = currentActions.value.map((a) => a.actionRef);
  incommingActionTypes.forEach((actionRef) => {
    if (existingActionTypes.includes(actionRef)) return;

    const action = {
      actionRef,
      analyticalAxesList,
      observationsList,
      isSuggested: true,
      status: "TODO",
      contributors: [],
      uuid: uuidv4(),
      quantity: 1,
    };
    currentActions.value.push(action);
  });
};

export const createOrUpdateAction = ({
  payload,
  analyticalAxesList,
  currentUser,
  observationUuid,
}) => {
  let periodicityData = null;
  if (payload.periodicity || payload.periodicityData) {
    periodicityData = {
      ...payload.periodicityData,
      uuid: payload.periodicityData?.uuid || uuidv4(),
    };
  }

  const created = !payload.uuid;
  const item = {
    ...payload,
    analyticalAxesList,
    periodicity: periodicityData ? periodicityData.uuid : null,
    periodicityData: periodicityData ? { ...periodicityData } : null,
    createdBy: payload?.createdBy || currentUser.usermanagementUuid,
    createdByFirstname: payload?.createdByFirstname || currentUser.firstName,
    createdByLastname: payload?.createdByLastname || currentUser.lastName,
    modifiedBy: payload?.modifiedBy || currentUser.usermanagementUuid,
    modifiedByFirstname: payload?.modifiedByFirstname || currentUser.firstName,
    modifiedByLastname: payload?.modifiedByLastname || currentUser.lastName,
    observationsList: payload?.observationsList || [observationUuid],
    createdAt: payload?.createdAt || new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    uuid: payload.uuid || uuidv4(),
  };
  console.log("actiioooooon", item);

  return [item, created];
};

export const makeInheritedActions = async ({
  existingActions,
  aosObservablesList,
  observationCollection,
}) => {
  const observationIds = existingActions.flatMap((a) => a.observationsList);

  // Fetch all relevant observations in a single query
  const selector = { uuid: { $in: observationIds } };
  const rxDocObservations = await observationCollection((collection) =>
    collection.find({ selector }).exec()
  );

  // Process all observations to get observables
  const observationsObservablesMap = rxDocObservations.reduce((acc, doc) => {
    const observation = doc.toJSON();
    acc[observation.uuid] = observation.aosObservablesList;
    return acc;
  }, {});

  // Map actions with their observables
  const actionsWithObservables = existingActions.map((action) => ({
    ...action,
    observablesList: [
      ...new Set(
        action.observationsList.flatMap((id) => observationsObservablesMap[id])
      ),
    ],
  }));

  return actionsWithObservables
    .filter((action) =>
      action.observablesList.some((observable) =>
        aosObservablesList.includes(observable)
      )
    )
    .map((action) => ({
      ...action,
      isInherited: true,
    }));
};

export const handleInheritedActions = async ({
  actionCollection,
  observationCollection,
  aosObservablesList,
  currentActions,
  observationUuid,
  analyticalAxisUuid,
  initialObservation,
}) => {
  // no aos observables means the observation is not yet set
  if (!aosObservablesList?.length) return;
  // inherited actions are only suggested for fisrst creation
  // if the observation is already created, we don't need to suggest inherited actions
  if (initialObservation) return;

  const rxDocActions = await actionCollection((c) => c.find().exec());
  const existingActions = rxDocActions.map((item) => item.toMutableJSON());
  const eligibleInheritedActions = await makeInheritedActions({
    existingActions,
    aosObservablesList,
    observationCollection,
  });

  const inheritedActionsNotInCurrent = differenceBy(
    eligibleInheritedActions,
    currentActions.value,
    "uuid"
  );
  if (!inheritedActionsNotInCurrent.length) return;

  inheritedActionsNotInCurrent.forEach((action) => {
    if (!action.observationsList.includes(observationUuid)) {
      action.observationsList.push(observationUuid);
    }

    if (!action.analyticalAxesList.includes(analyticalAxisUuid)) {
      action.analyticalAxesList.push(analyticalAxisUuid);
    }

    currentActions.value.push(action);
  });
};

export const handleActions = async ({ incomingActions, actionCollection }) => {
  const db = RxDB.getInstance();
  const formattedActions = incomingActions.map((action) => {
    return {
      ...action,
      quantity: parseInt(action.quantity),
      totalAmount: parseFloat(action.totalAmount) || 0,
      periodicity: action.periodicity || "",
      date: action.date ?? "",
    };
  });

  const periodicityData = formattedActions
    .map((action) => {
      return action.periodicityData
        ? {
            ...action.periodicityData,
            number: parseInt(action.periodicityData?.number),
          }
        : null;
    })
    .filter((item) => item !== null);

  await handlePeriodicities(periodicityData);

  const formatedData = makeSchemaCompliantKeepingKeys(
    formattedActions,
    db["action"].getFields()
  );

  const { error } = await actionCollection((c) => c.bulkUpsert(formatedData));
  if (error.length) {
    console.error("Error while inserting/updating actions in rxdb", error);
  }

  await Action.insertOrUpdate({ data: formattedActions });
};

export const handlePeriodicities = async (periodicities) => {
  const { execOnCollection: PeriodicityCollection } =
    useRxdbCollection("periodicity");

  await PeriodicityCollection((c) => c.bulkUpsert(periodicities), false);

  await Periodicity.insertOrUpdate({ data: periodicities });
};
