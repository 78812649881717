const COMPARATORS = {
  EQUAL: "==",
  GREATER: ">",
  GREATER_EQUAL: ">=",
  LESS: "<",
  LESS_EQUAL: "<=",
  IN: "in",
  NOT_IN: "not in",
  STARTSWITH: "startswith",
  CONTAINS: "contains",
};

const OPERATORS = {
  AND: "AND",
  OR: "OR",
};

export class FilterRule {
  constructor({
    sourceEntity = null,
    sourceAttr = "",
    targetEntity,
    targetAttrs = [],
    getCollection = () => [],
    mapping = {},
    apply = null,
    includeNodeWhenValueIsEmpty = false,
  }) {
    this.sourceEntity = sourceEntity;
    this.sourceAttr = sourceAttr;
    this.targetEntity = targetEntity;
    this.targetAttrs = targetAttrs;
    this.getCollection = getCollection;
    this.mapping = mapping;
    this.collection = [];
    this.apply = apply;
    this.includeNodeWhenValueIsEmpty = includeNodeWhenValueIsEmpty;
  }

  // Init rule with data used to filter
  init(data) {
    const collection = this.getCollection(data);

    if (!Array.isArray(collection)) {
      throw Error(
        "Check your referencial mapping rules. The getCollection function must always return an array"
      );
    }
    // Clean up values to simplify rule interpretation
    this.collection = collection.filter((item) => {
      return item !== "" && item !== undefined && item !== null;
    });
  }
}

class ReferencialFilter {
  replaceByMappedValues(source, mapping) {
    if (typeof source === "string" && source in mapping) {
      return mapping[source];
    } else if (Array.isArray(source)) {
      return source.flatMap((s) => (s in mapping ? mapping[s] : s));
    }
    return source;
  }
}

export class ReferencialStaticFilter extends ReferencialFilter {
  constructor(rules, data) {
    super();
    // init rules
    this.rules = rules.map((rule) => {
      rule.init(data);
      return rule;
    });

    this.data = data;
  }

  apply(node) {
    return this.rules.every((rule) => {
      if (!Object.hasOwn(node, rule.sourceAttr)) return true;
      const source = this.replaceByMappedValues(
        node[rule.sourceAttr],
        rule.mapping
      );
      if (!source || (Array.isArray(source) && source.length === 0)) {
        return true;
      }
      try {
        return rule.apply(source, rule.collection);
      } catch (e) {
        console.error(e);
        return true;
      }
    });
  }
}

export class ReferencialConditionalFilter extends ReferencialFilter {
  constructor(rules, data) {
    super();
    // init rules
    Object.keys(rules).forEach((key) => {
      rules[key].init(data);
    });
    this.rules = rules;
    this.data = data;
  }

  compare(source, comparator, target) {
    switch (comparator) {
      case COMPARATORS.GREATER:
        return source > target;
      case COMPARATORS.LESS:
        return source < target;
      case COMPARATORS.GREATER_EQUAL:
        return source >= target;
      case COMPARATORS.LESS_EQUAL:
        return source <= target;
      case COMPARATORS.EQUAL:
        return source === target;
      case COMPARATORS.IN:
        return target.includes(source);
      case COMPARATORS.NOT_IN:
        return !target.includes(source);
      case COMPARATORS.STARTSWITH:
        return target.startsWith(source);
      case COMPARATORS.CONTAINS:
        return target.includes(source);
    }
  }

  resolve(source, operator, target) {
    switch (operator) {
      case OPERATORS.AND:
        return source && target;
      case OPERATORS.OR:
        return source || target;
    }
  }

  getConditionalDisplayMapping(sourceEntity, sourceAttr) {
    return this.rules[`${sourceEntity}|${sourceAttr}`];
  }

  getComputations(displayRule) {
    return ["first_value", "second_value"]
      .map((key) => {
        const opData = displayRule[key]?.attribute;
        if (opData) {
          const [entity, attr] = opData;
          const { comparator, value } = displayRule[key];
          const mappedRule = this.getConditionalDisplayMapping(entity, attr);

          if (!mappedRule) return null;

          return {
            comparator,
            value,
            mappedRule,
          };
        }

        return null;
      })
      .filter(Boolean);
  }

  apply(node) {
    return node.conditionalDisplay.every((displayRule) => {
      const logicOperator = displayRule.logic_operator;
      const computations = this.getComputations(displayRule);

      // Mapping not found for this conditional display: Ignore the rule
      if (!computations?.length) return true;

      // Interpret conditions
      return computations.reduce((prev, cur) => {
        const { mappedRule } = cur;
        const { collection } = mappedRule;
        // Include node when values to be compared are empty
        // Only if the rule specifies to ignore the rule in this case
        if (!collection?.length && collection?.includeNodeWhenValueIsEmpty) {
          return true;
        }
        const res = collection.some((item) =>
          this.compare(item, cur.comparator, cur.value)
        );
        return prev ? this.resolve(prev, logicOperator, res) : res;
      }, null);
    });
  }
}
