/**
 * WARNING
 * This module is used by the worker and the main thread.
 * Be careful when adding new code to this file. It must be kept free of any
 * dependencies that reference the window or document objects.
 * These include but are not limited to:
 *
 * SocioAosComponent
 * SocioVueComponent
 * VuexORM
 */
export const ANALYTICAL_AXIS_CODES = {
  // This is a copy of AnalyticalAxisEnum from Amos Back
  DOC_REVIEW: "DOC_REVIEW",
  FIRE_SAFETY: "FIRE_SAFETY",
  ACCESSIBILITY: "ACCESSIBILITY",
  DILAPIDATION: "DILAPIDATION",
  ENERGY: "ENERGY",
  TAXONOMY_CONSTRUCTION: "TAXONOMY_CONSTRUCTION",
  TAXONOMY_EXPLOITATION: "TAXONOMY_EXPLOITATION",
  TAXONOMY_RENOVATION: "TAXONOMY_RENOVATION",
  ADAPTABILITY: "ADAPTABILITY",
  FLEXIBILITY: "FLEXIBILITY",
  DURABILITY: "DURABILITY",
  RECYCLE: "RECYCLE",
  LOW_CARBONE: "LOW_CARBONE",
  WIRED_CONSTRUCTION: "WIRED_CONSTRUCTION",
  WIRED_EXPLOITATION: "WIRED_EXPLOITATION",
  SI_DIAG: "SI_DIAG",
  AH_DIAG: "AH_DIAG",
  IRVE: "IRVE",
  CONSERVATION: "CONSERVATION",
  RUSTING: "RUSTING",
  TECHNICAL_ADVICE: "TECHNICAL_ADVICE",
  FUNCTIONAL_REQUIREMENTS: "FUNCTIONAL_REQUIREMENTS",
};

export const MISSION_CODES = {
  // PICASSO
  HAIA: "HAIA",
  // AMOS_INFRA
  HAAD: "HAAD",
  HIBA: "HIBA",
  JFAD: "JFAD",
  // AMOS_DIAG
  HBAG: "HBAG",
  HZAA: "HZAA",
  // AMOS_REAL_ESTATE
  JFAB: "JFAB",
  JFAF: "JFAF",
  JFAL: "JFAL",
  // BLUE_CASTLE
  LQAD: "LQAD",
};

export const EXPENSE_TYPE = {
  CAPEX: "CAPEX",
  OPEX: "OPEX",
};

export const bimReferencials = [
  "IFC 2x3",
  "IFC4",
  "Uniformat II",
  "FIDJI",
  "ICMS/IPMS",
  "UNTEC",
  "Omnicalss",
  "Uniclass",
];

export const ROLES = {
  PILOT: "PILOT",
  PROJECT_MANAGER: "PROJECT_MANAGER",
  TECHNICAL_CONTROLLER: "TECHNICAL_CONTROLLER",
};

export const reportTemplateFileTypeEnum = {
  PDF: "PDF",
  EXCEL: "EXCEL",
};

export const reportLanguageChoiceEnum = {
  EN: "EN",
  FR: "FR",
};

export const countries = {
  France: "France",
};

export const RAPSOTEC_CT_REFERENCIAL_PARAMS = {
  versionName: "CURRENT",
  versionNumber: 1,
  applicationName: "CT_RAPSOTEC_FRANCE",
  referencialType: "NATIONAL",
  referencialName: "DEFAULT",
};

export const RAPSOTEC_CT_PATHES = {
  FIRE_SAFETY: "20",
  ACCESSIBILITY: "33",
};

export const DISPOSITION_PATHES = {
  1: "1", // DILAPIDATION
  2: "2", // ENERGY
  3: "3", // FIRE_SAFETY
  4: "4", // ACCESSIBILITY
  18: "18", // CONSERVATION
  19: "19", // RUSTING
  20: "20", // TECHNICAL_ADVICE
  21: "21", // FUNCTIONAL_REQUIREMENTS
};

export const ANALYTICAL_AXES_TO_DISPOSITION_PATHES = {
  // each analytical axis is linked to a disposition path
  // for example, a node with a path like so: "1.1.1.1" will be linked to the disposition path "1"
  [ANALYTICAL_AXIS_CODES.DILAPIDATION]: DISPOSITION_PATHES[1],
  [ANALYTICAL_AXIS_CODES.ENERGY]: DISPOSITION_PATHES[2],
  [ANALYTICAL_AXIS_CODES.FIRE_SAFETY]: DISPOSITION_PATHES[3],
  [ANALYTICAL_AXIS_CODES.ACCESSIBILITY]: DISPOSITION_PATHES[4],
  // no more disposition pathes between 5 and 17 for now (07/11/2023)
  [ANALYTICAL_AXIS_CODES.CONSERVATION]: DISPOSITION_PATHES[18],
  [ANALYTICAL_AXIS_CODES.RUSTING]: DISPOSITION_PATHES[19],
  [ANALYTICAL_AXIS_CODES.TECHNICAL_ADVICE]: DISPOSITION_PATHES[20],
  [ANALYTICAL_AXIS_CODES.FUNCTIONAL_REQUIREMENTS]: DISPOSITION_PATHES[21],
};

export const ANALYTICAL_AXES_TO_RAPSOTEC_CT_NODE_PATHES = {
  [ANALYTICAL_AXIS_CODES.AH_DIAG]: RAPSOTEC_CT_PATHES.ACCESSIBILITY,
  [ANALYTICAL_AXIS_CODES.SI_DIAG]: RAPSOTEC_CT_PATHES.FIRE_SAFETY,
};

export const DISPOSITION_PATHES_TO_ANALYTICAL_AXES = {
  // inversely, each disposition path is linked to an analytical axis
  [DISPOSITION_PATHES[1]]: ANALYTICAL_AXIS_CODES.DILAPIDATION,
  [DISPOSITION_PATHES[2]]: ANALYTICAL_AXIS_CODES.ENERGY,
  [DISPOSITION_PATHES[3]]: ANALYTICAL_AXIS_CODES.FIRE_SAFETY,
  [DISPOSITION_PATHES[4]]: ANALYTICAL_AXIS_CODES.ACCESSIBILITY,
  // no more disposition pathes between 5 and 17 for now (04/13/2024)
  [DISPOSITION_PATHES[18]]: ANALYTICAL_AXIS_CODES.CONSERVATION,
  [DISPOSITION_PATHES[19]]: ANALYTICAL_AXIS_CODES.RUSTING,
  [DISPOSITION_PATHES[20]]: ANALYTICAL_AXIS_CODES.TECHNICAL_ADVICE,
  [DISPOSITION_PATHES[21]]: ANALYTICAL_AXIS_CODES.FUNCTIONAL_REQUIREMENTS,
};

export const DIAG_MISSION_CODES_TO_ANALYTICAL_AXES = {
  [MISSION_CODES.HZAA]: ANALYTICAL_AXIS_CODES.AH_DIAG,
  [MISSION_CODES.HBAG]: ANALYTICAL_AXIS_CODES.SI_DIAG,
};

export const RAPSOTEC_CT_PATHES_TO_AXES_CODES = {
  [RAPSOTEC_CT_PATHES.FIRE_SAFETY]: ANALYTICAL_AXIS_CODES.SI_DIAG,
  [RAPSOTEC_CT_PATHES.ACCESSIBILITY]: ANALYTICAL_AXIS_CODES.AH_DIAG,
};

export const assetTypesByRoute = {
  "document-review": ["site"],
  dispositions: ["site", "building"],
};

export const missionCodesStructureMap = {
  building: ["JFAL", "JFAB", "JFAF"],
  infrastructure: ["HIBA", "HAAD", "JFAD"],
};

export const riskGroupsEnum = {
  SEVERITY: "Gravité",
  INVESTOR: "Risque investisseur",
};

export const riskGroupByMissionCodeMap = {
  // INFRA Mission Codes
  [MISSION_CODES.HAAD]: riskGroupsEnum.SEVERITY,
  [MISSION_CODES.HIBA]: riskGroupsEnum.SEVERITY,
  [MISSION_CODES.JFAD]: riskGroupsEnum.SEVERITY,

  // DIAG Mission Codes
  [MISSION_CODES.HBAG]: riskGroupsEnum.INVESTOR,
  [MISSION_CODES.HZAA]: riskGroupsEnum.INVESTOR,

  // REAL_ESTATE_QI Mission Codes
  [MISSION_CODES.JFAB]: riskGroupsEnum.INVESTOR,
  [MISSION_CODES.JFAF]: riskGroupsEnum.INVESTOR,
  [MISSION_CODES.JFAL]: riskGroupsEnum.INVESTOR,

  // PICASSO Mission Code (WIP)
  [MISSION_CODES.HAIA]: riskGroupsEnum.INVESTOR,
};

export const PICASSO_ANALYTICAL_AXES = ["FUNCTIONAL_REQUIREMENTS"];

export const VIEWER_PLUGINS = {
  GED: "GedPlugin",
  ZOOM_IN: "ZoomInPlugin",
  ZOOM_OUT: "ZoomOutPlugin",
  EXPORT_FILE: "ExportFilePlugin",
};

export const APP_NAMES = {
  PICASSO: "PICASSO",
  AMOS_INFRA: "AMOS_INFRA",
  AMOS_REAL_ESTATE: "AMOS",
  AMOS_DIAG: "RAPSODIAG",
  BLUE_TRUST_IMMOBILIER: "BLUETRUST IMMOBILIER",
};

export const DOMAIN_TO_APP_NAME = {
  amos: APP_NAMES.AMOS_REAL_ESTATE,
  bluetrustimmobilier: APP_NAMES.BLUE_TRUST_IMMOBILIER,
  // picasso: APP_NAMES.PICASSO,
  // etc.
};

export const TASK_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  CANCELED: "CANCELED",
  TIMEOUT: "TIMEOUT",
};

export const MISSION_CODES_TO_APP = {
  [MISSION_CODES.HAIA]: APP_NAMES.PICASSO,
  [MISSION_CODES.HAAD]: APP_NAMES.AMOS_INFRA,
  [MISSION_CODES.HIBA]: APP_NAMES.AMOS_INFRA,
  [MISSION_CODES.JFAD]: APP_NAMES.AMOS_INFRA,
  [MISSION_CODES.HBAG]: APP_NAMES.AMOS_DIAG,
  [MISSION_CODES.HZAA]: APP_NAMES.AMOS_DIAG,
  [MISSION_CODES.JFAB]: APP_NAMES.AMOS_REAL_ESTATE,
  [MISSION_CODES.JFAF]: APP_NAMES.AMOS_REAL_ESTATE,
  [MISSION_CODES.JFAL]: APP_NAMES.AMOS_REAL_ESTATE,
  [MISSION_CODES.LQAD]: APP_NAMES.BLUE_TRUST_IMMOBILIER,
};

export const ANALYTICAL_AXIS_GROUPS = {
  DISPOSITION: [
    ANALYTICAL_AXIS_CODES.DOC_REVIEW,
    ANALYTICAL_AXIS_CODES.FIRE_SAFETY,
    ANALYTICAL_AXIS_CODES.ACCESSIBILITY,
    ANALYTICAL_AXIS_CODES.DILAPIDATION,
    ANALYTICAL_AXIS_CODES.ENERGY,
    ANALYTICAL_AXIS_CODES.TAXONOMY_CONSTRUCTION,
    ANALYTICAL_AXIS_CODES.TAXONOMY_EXPLOITATION,
    ANALYTICAL_AXIS_CODES.TAXONOMY_RENOVATION,
    ANALYTICAL_AXIS_CODES.ADAPTABILITY,
    ANALYTICAL_AXIS_CODES.FLEXIBILITY,
    ANALYTICAL_AXIS_CODES.DURABILITY,
    ANALYTICAL_AXIS_CODES.RECYCLE,
    ANALYTICAL_AXIS_CODES.LOW_CARBONE,
    ANALYTICAL_AXIS_CODES.WIRED_CONSTRUCTION,
    ANALYTICAL_AXIS_CODES.WIRED_EXPLOITATION,
    ANALYTICAL_AXIS_CODES.IRVE,
    ANALYTICAL_AXIS_CODES.TECHNICAL_ADVICE,
    ANALYTICAL_AXIS_CODES.FUNCTIONAL_REQUIREMENTS,
  ],
  TECHNICAL_INSPECTION: [
    ANALYTICAL_AXIS_CODES.AH_DIAG,
    ANALYTICAL_AXIS_CODES.SI_DIAG,
  ],
};

export const ADMIN_MODELS_AND_COLLECTIONS = [
  ["DispositionType", "disposition_type"],
  ["TypologyType", "typology_type"],
  ["ActionType", "action_type"],
  ["ActionStatus", "action_status"],
  ["StatementType", "statement_type"],
  ["Quotation", "quotation"],
  ["QuotationValue", "quotation_value"],
  ["QuotationGroup", "quotation_group"],
  ["QuotationGroupType", "quotation_group_type"],
  ["QuotationAnalyticalAxis", "quotation_analytical_axis"],
  ["ROIGroup", "r_o_i_group"],
  ["ROI", "r_o_i"],
  ["PriorityGroup", "priority_group"],
  ["Priority", "priority"],
  ["CostScaleGroup", "cost_scale_group"],
  ["CostScale", "cost_scale"],
  ["DifferabilityGroup", "differability_group"],
  ["Differability", "differability"],
  ["ProjectionGroup", "projection_group"],
  ["Projection", "projection"],
  ["VentilationGroup", "ventilation_group"],
  ["Ventilation", "ventilation"],
  ["SourcePricingGroup", "source_pricing_group"],
  ["SourcePricing", "source_pricing"],
  ["RiskAssessment", "risk_assessment"],
  ["AnalyticalAxis", "analytical_axis"],
  ["RiskAssessmentGroup", "risk_assessment_group"],
  ["ReportTemplateType", "report_template_type"],
  ["FunctionalRequirement", "functional_requirement"],
  ["ObjectTypeStatement", "object_type_statement"],
  ["Glossary", "glossary"],
  ["SurveyRule", "survey_rule"],
  ["ReportText", "report_text"],
];

export const referencialVersionNames = {
  CURRENT: "CURRENT",
  OLD: "OLD",
  DRAFT: "DRAFT",
};

export const GRPC_REFERENCIAL_PARAMS = {
  AMOS_DISPOSITION_CURRENT_PARAMS: {
    versionName: "CURRENT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DISPOSITION",
    projectUuid: "",
  },
  AMOS_DISPOSITION_OLD_PARAMS: {
    versionName: "OLD",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DISPOSITION",
    projectUuid: "",
  },
  AMOS_DISPOSITION_DRAFT_PARAMS: {
    versionName: "DRAFT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DISPOSITION",
    projectUuid: "",
  },
  AMOS_DOC_REVIEW_CURRENT_PARAMS: {
    versionName: "CURRENT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DOC_REVIEW",
    projectUuid: "",
  },
  AMOS_DOC_REVIEW_OLD_PARAMS: {
    versionName: "OLD",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DOC_REVIEW",
    projectUuid: "",
  },
  AMOS_DOC_REVIEW_DRAFT_PARAMS: {
    versionName: "DRAFT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DOC_REVIEW",
    projectUuid: "",
  },
  RAPSOTEC_CT_CURRENT_PARAMS: {
    versionName: "CURRENT",
    versionNumber: 0,
    applicationName: "CT_RAPSOTEC_FRANCE",
    referencialType: "NATIONAL",
    referencialName: "DEFAULT",
    projectUuid: "",
  },
};

export const AMOS_LANGUAGES = {
  fr: {
    code: "fr",
    label: "Français",
  },
  "en-gb": {
    code: "en-gb",
    label: "English (UK)",
  },
};

export const dispositionNodeExcludedFields = [
  "dispositionType",
  "typologyType",
  "analyticalAxis",
];

export const baseExcludedFields = [
  "$id",
  "level",
  "numchild",
  "referencialName",
  "spsNode",
  "isDisplay",
  "isOpen",
  "isSelected",
  "readablePath",
  "modificationOf",
  "path",
  "createdAt",
  "updatedAt",
  "children",
  "rapsotecNode",
  "rootNodePath",
  "childrenList",
].concat(dispositionNodeExcludedFields);

export const archiveExcludedFields = [
  "label",
  "description",
  "code",
  "conditionalTitles",
  "conditionalDisplay",
  "manuallyCreated",
  "parentNode",
  "objectId",
].concat(baseExcludedFields);

export const localesMap = {
  fr: "Fr",
  "en-gb": "EnGb",
};
