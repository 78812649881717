import { AosObservable } from "@/models";
import { forIn, groupBy } from "lodash";
import { ASSET_ITEMS } from "@/utils/assetItems";

export const formatAndInsertAssets = async (aosObservablesList) => {
  const assetReprByType = groupAndMapAssetsBy(aosObservablesList, (asset) => ({
    uuid: asset.assetUuid,
    aosItem: asset.aosItem,
  }));

  for (const assetType of Object.keys(ASSET_ITEMS)) {
    if (!assetReprByType[assetType] || !assetReprByType[assetType].length) {
      continue;
    }
    await ASSET_ITEMS[assetType].modelClass().insert({
      data: assetReprByType[assetType],
    });
  }
};

export const formatAndInsertObservables = async (observables) => {
  await AosObservable.insert({
    data: observables.map(buildObservableObj),
  });
};

export const groupAndMapAssetsBy = (data, mapFn) => {
  const groupedAssets = groupBy(data, "assetType");
  const mappedAssets = {};
  forIn(groupedAssets, (assetItems, assetType) => {
    mappedAssets[assetType] = assetItems.map(mapFn);
  });
  return mappedAssets;
};

const buildObservableObj = (item) => {
  return {
    ...item,
    assetModel: `${item.assetType.toLowerCase()}Assets`,
  };
};
