import {
  ActionType,
  ActionStatus,
  StatementType,
  Quotation,
  QuotationAnalyticalAxis,
  QuotationValue,
  QuotationGroup,
  QuotationGroupType,
  ROIGroup,
  PriorityGroup,
  Priority,
  CostScaleGroup,
  DifferabilityGroup,
  ProjectionGroup,
  VentilationGroup,
  SourcePricingGroup,
  RiskAssessment,
  Ventilation,
  ROI,
  Differability,
  Projection,
  SourcePricing,
  CostScale,
  AnalyticalAxis,
  ReportText,
  Glossary,
  SurveyRule,
  LabelCertificationLabel,
  LabelCertificationLevel,
  LabelCertificationLifecycle,
  LabelCertificationPanel,
  LabelCertificationType,
  LabelCertificationVersion,
  RiskAssessmentGroup,
  ReportTemplateType,
  FunctionalRequirement,
  TypologyType,
  DispositionType,
} from "@/models";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";

export const amosAdminModels = () => [
  ActionType,
  ActionStatus,
  AnalyticalAxis,
  CostScale,
  CostScaleGroup,
  Differability,
  DifferabilityGroup,
  DispositionType,
  FunctionalRequirement,
  Glossary,
  Priority,
  PriorityGroup,
  Projection,
  ProjectionGroup,
  Quotation,
  QuotationAnalyticalAxis,
  QuotationGroup,
  QuotationGroupType,
  QuotationValue,
  ReportTemplateType,
  ReportText,
  RiskAssessment,
  RiskAssessmentGroup,
  ROIGroup,
  ROI,
  SourcePricing,
  SourcePricingGroup,
  StatementType,
  SurveyRule,
  TypologyType,
  Ventilation,
  VentilationGroup,
];

// INFO - NL - 13/02/2023 - Add a property if you want to add a new section in the /admin page
export const AMOS_ADMIN_SECTIONS = {
  priority: ["priority", "priorityGroup"],
  ventilation: ["ventilation", "ventilationGroup"],
  differability: ["differability", "differabilityGroup"],
  ROI: ["ROI", "ROIGroup"],
  projection: ["projection", "projectionGroup"],
  sourcePricing: ["sourcePricing", "sourcePricingGroup"],
  costScale: ["costScale", "costScaleGroup"],
  riskAssessment: ["riskAssessment", "riskAssessmentGroup"],
  analyticalAxis: ["analyticalAxis"],
  statementType: ["statementType"],
  actionStatus: ["actionStatus"],
  actionType: ["actionType"],
  quotation: [
    "quotationGroupType",
    "quotationGroup",
    "quotation",
    "quotationValue",
    "quotationAnalyticalAxis",
  ],
  reportText: ["reportText"],
  surveyRule: ["surveyRule"],
  glossary: ["glossary"],
  labelCertification: [
    "labelCertificationLifecycle",
    "labelCertificationType",
    "labelCertificationLabel",
    "labelCertificationVersion",
    "labelCertificationPanel",
    "labelCertificationLevel",
  ],
  objectTypeStatement: ["objectTypeStatement"],
  reportTemplateType: ["reportTemplateType"],
  componentTypology: ["componentTypology"],
};

// INFO - NL - 13/02/2023 - Add new property with you want that the model is editable in the /admin page
// Note that you also need to create the route and the component
export const AMOS_ADMIN_ITEMS = {
  priorityGroup: {
    modelClass: PriorityGroup,
    childModelKey: "priority",
  },
  priority: {
    modelClass: Priority,
    parentModelKey: "priorityGroup",
  },
  ventilationGroup: {
    modelClass: VentilationGroup,
    childModelKey: "ventilation",
  },
  ventilation: {
    modelClass: Ventilation,
    parentModelKey: "ventilationGroup",
  },
  differabilityGroup: {
    modelClass: DifferabilityGroup,
    childModelKey: "differability",
  },
  differability: {
    modelClass: Differability,
    parentModelKey: "differabilityGroup",
  },
  ROIGroup: {
    modelClass: ROIGroup,
    childModelKey: "ROI",
  },
  ROI: {
    modelClass: ROI,
    parentModelKey: "ROIGroup",
  },
  projectionGroup: {
    modelClass: ProjectionGroup,
    childModelKey: "projection",
  },
  projection: {
    modelClass: Projection,
    parentModelKey: "projectionGroup",
  },
  sourcePricingGroup: {
    modelClass: SourcePricingGroup,
    childModelKey: "sourcePricing",
  },
  sourcePricing: {
    modelClass: SourcePricing,
    parentModelKey: "sourcePricingGroup",
  },
  costScaleGroup: {
    modelClass: CostScaleGroup,
    childModelKey: "costScale",
  },
  costScale: {
    modelClass: CostScale,
    parentModelKey: "costScaleGroup",
  },
  riskAssessment: {
    modelClass: RiskAssessment,
  },
  riskAssessmentGroup: {
    modelClass: RiskAssessmentGroup,
    childModelKey: "riskAssessment",
  },
  analyticalAxis: {
    modelClass: AnalyticalAxis,
  },
  statementType: {
    modelClass: StatementType,
  },
  quotationGroupType: {
    modelClass: QuotationGroupType,
  },
  quotationGroup: {
    modelClass: QuotationGroup,
    childModelKey: "quotation",
  },
  quotation: {
    modelClass: Quotation,
    childModelKey: "quotationValue",
  },
  quotationValue: {
    modelClass: QuotationValue,
  },
  quotationAnalyticalAxis: {
    modelClass: QuotationAnalyticalAxis,
    childModelKey: "quotation",
  },
  actionStatus: {
    modelClass: ActionStatus,
  },
  actionType: {
    modelClass: ActionType,
  },
  reportText: {
    modelClass: ReportText,
  },
  glossary: {
    modelClass: Glossary,
  },
  surveyRule: {
    modelClass: SurveyRule,
  },
  labelCertificationLifecycle: {
    modelClass: LabelCertificationLifecycle,
  },
  labelCertificationType: {
    modelClass: LabelCertificationType,
  },
  labelCertificationLabel: {
    modelClass: LabelCertificationLabel,
    childModelKey: "labelCertificationLifecycle",
  },
  labelCertificationVersion: {
    modelClass: LabelCertificationVersion,
  },
  labelCertificationPanel: {
    modelClass: LabelCertificationPanel,
  },
  labelCertificationLevel: {
    modelClass: LabelCertificationLevel,
  },
  reportTemplateType: {
    modelClass: ReportTemplateType,
  },
  componentTypology: {
    modelClass: AosVuexOrmModel.AosTypology,
  },
  functionalRequirement: {
    modelClass: FunctionalRequirement,
  },
};
