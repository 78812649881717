import { utils } from "@socotec.io/socio-aos-component";
import {
  SiteAsset,
  BuildingAsset,
  StoreyAsset,
  ZoneAsset,
  SpaceAsset,
  SubcomponentAsset,
  ComponentAsset,
} from "@/models";

export const ASSET_ITEMS = {
  site: {
    ...utils.aosConst.AOS_ITEMS.site,
    modelClass: () => SiteAsset,
    identityCard: "SiteIdentityCard",
  },

  building: {
    ...utils.aosConst.AOS_ITEMS.building,
    modelClass: () => BuildingAsset,
    identityCard: "SiteIdentityCard",
  },

  storey: {
    ...utils.aosConst.AOS_ITEMS.storey,
    modelClass: () => StoreyAsset,
    identityCard: "StoreyIdentityCard",
  },

  zone: {
    ...utils.aosConst.AOS_ITEMS.zone,
    modelClass: () => ZoneAsset,
    identityCard: "",
  },

  space: {
    ...utils.aosConst.AOS_ITEMS.space,
    modelClass: () => SpaceAsset,
    identityCard: "",
  },

  component: {
    ...utils.aosConst.AOS_ITEMS.component,
    modelClass: () => ComponentAsset,
    identityCard: "ComponentIdentityCard",
  },

  subcomponent: {
    ...utils.aosConst.AOS_ITEMS.subcomponent,
    modelClass: () => SubcomponentAsset,
    identityCard: "ComponentIdentityCard",
  },
};
